import React from "react";

const ImageWidget = (props) => {
    let url = props.state.imageUrl
    
  return (
    <div>
    <img src={url} width="400px" height="400px" style={{border: "1px solid #fc5a6a"}} />
    </div>
  );
};

export default ImageWidget;
