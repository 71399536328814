import React from "react";
// import axiosInstance from "..";
import { getAgent, getSuggestions } from "./common/helper";
import { createClientMessage, createCustomMessage } from "react-chatbot-kit";
import { useSessionContext } from "../context/SessionContext";

const DUMMY_MESSAGE = [
  "Crafting an answer...",
  "Let me think...",
  "I am thinking...",
];

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const { setSuggestions, setOpen } = useSessionContext();
  const handleEmptyMessage = () => {
    loadMessage(
      "It seems like your message is empty. How can I assist you today?",
      false
    );
  };
  const createBotResponse = (question) => {
    loadMessage("Do you want to know " + question, false);
  };
  const createClientResponse = (question) => {
    let message = createClientMessage(question, { loading: true });
    addMessageToState(message);
  };

  const handleMessage = async (message, agent = getAgent()) => {
    if (agent === "Generate_Image") return handleGenerateImage();
    const sessionId = localStorage.getItem("widget_sessionId");
    // addMessageToState(message);
    // addLoader();
    setState((state) => ({ ...state, loading: true }));
    document.getElementsByClassName(
      "react-chatbot-kit-chat-input"
    )[0].disabled = true;
    document.getElementsByClassName(
      "react-chatbot-kit-chat-btn-send"
    )[0].disabled = true;
    const formData = new FormData();
    formData.append("session_id", sessionId);
    formData.append("prompt", message);
    formData.append("message_type", "Text");
    const msg = createChatBotMessage("", {
      loading: true,
      widget: "message",
      payload: "",
    });
    addMessageToState(msg);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DAIZY_CHAT_BASE_URL}/conversations`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_DAIZY_USERNAME +
                  ":" +
                  process.env.REACT_APP_DAIZY_PASSWORD
              ),
          },
          body: formData,
        }
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      const loopRunner = true;
      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          document.getElementsByClassName(
            "react-chatbot-kit-chat-input"
          )[0].disabled = false;
          document.getElementsByClassName(
            "react-chatbot-kit-chat-btn-send"
          )[0].disabled = false;
          setState((state) => ({ ...state, loading: false }));
          const data = await getSuggestions();
          if (data.length > 0) {
            setSuggestions(data);
            setOpen(true);
          }
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        setState((state) => {
          const updatedMessages = [...state.messages];
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            payload:
              updatedMessages[updatedMessages.length - 1].payload +
              decodedChunk,
          };
          return {
            ...state,
            messages: updatedMessages,
          };
        });
      }
    } catch (error) {
      console.error("Error", error);
      document.getElementsByClassName(
        "react-chatbot-kit-chat-input"
      )[0].disabled = false;
      document.getElementsByClassName(
        "react-chatbot-kit-chat-btn-send"
      )[0].disabled = false;
      setState((state) => ({ ...state, loading: false }));
      setState((state) => {
        const updatedMessages = [...state.messages];
        updatedMessages[updatedMessages.length - 1] = {
          ...updatedMessages[updatedMessages.length - 1],
          payload: (updatedMessages[updatedMessages.length - 1].payload =
            "Sorry an error occurred, Please try again."),
        };
        return {
          ...state,
          messages: updatedMessages,
        };
      });
    }
  };
  const handleGenerateImage = () => {
    loadMessage(
      "Start your journey with Dzylo One to get access to this feature!",
      false,
      "dzylo-buy-now"
    );
  };
  function removeLoadingMessage(prevstateArray, removeLoading) {
    if (removeLoading) {
      prevstateArray?.messages?.splice(
        prevstateArray?.messages?.findIndex((a) => {
          return a?.message === DUMMY_MESSAGE;
        }),
        1
      );
      return prevstateArray;
    } else {
      return prevstateArray;
    }
  }
  function addMessageToState(message, removeLoading = false) {
    setState((prev) => ({
      ...removeLoadingMessage(prev, removeLoading),
      messages: [...prev.messages, message],
    }));
  }
  function loadMessage(botMessage, removeLoading = false, type = "message") {
    let message = "";
    switch (type) {
      case "message": {
        message = createChatBotMessage(botMessage, {
          loading: true,
        });
        break;
      }
      case "image": {
        message = createChatBotMessage("Here is a Image that I created", {
          widget: "image",
        });
        break;
      }
      default: {
        message = createChatBotMessage(botMessage, { widget: type });
        break;
      }
    }
    addMessageToState(message, removeLoading);
  }

  function addLoader() {
    const message = createCustomMessage("value to input", "loader");
    addMessageToState(message);
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage,
            handleEmptyMessage,
            createBotResponse,
            createClientResponse,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
