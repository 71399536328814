import React from "react";

const MessageParser = ({ children, actions }) => {
  const parse = async message => {
    if (!localStorage.getItem("widget_sessionId")) {
      console.log("Session Id not found in parser");
      return;
    }
    if (children.props.state.loading) {
      children.props.state.messages.slice(0, -1);
      return;
    }
    if (message.length === 0) return actions.handleEmptyMessage(message);
    else await actions.handleMessage(message);
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
