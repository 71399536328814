import axiosInstance from "../../index";
let agent = "Document";
let tempSrcNumber = 0;

export const handleChatWithUs = () => {
  const text = `Hi, I want to ask `;
  window.open(
    `https://api.whatsapp.com/send/?phone=7303889822&text=${text}&type=phone_number&app_absent=0`
  );
};

export const generateAnswer = () => {
  const text = `Hi, I want to ask `;
  window.open(
    `https://api.whatsapp.com/send/?phone=7303889822&text=${text}&type=phone_number&app_absent=0`
  );
};

export const getAgent = () => agent;

export const updateAgent = (agentToChange) => {
  agent = agentToChange;
};

export const getTempSrcNumber = () => tempSrcNumber;

export const updateTempSrcNumber = () => {
  tempSrcNumber++;
};

export const getAnswer = (message) => {
  axiosInstance
    .post("	https://daizyapi.dzylo.com/daizy/chat", {
      question: message,
      agentType: getAgent(),
    })
    .then((result) => {
      return result.data;
      // setState((prev) => ({
      //     ...prev,
      //     messages: [...prev.messages, botMessage],
      //   }));
    })
    .catch((err) => console.log(err));
};

export const getFaq = (id, props, question) => {
  if (props.state.loading) return;
  props.actionProvider.createClientResponse(question);
  props.actionProvider.handleMessage(question, agent);
};

export const getSuggestions = async () => {
  const sessionId = localStorage.getItem("widget_sessionId");
  if (!sessionId) {
    console.log("Session Id not found in suggestions");
    return;
  }
  try {
    const response = await fetch(
      process.env.REACT_APP_DAIZY_RECOMEND_BASE_URL,
      {
        method: "POST",
        body: JSON.stringify({
          session_id: sessionId,
        }),
      }
    );
    return await response.json();
  } catch (error) {
    console.log("Error in fetching suggestions", error);
  }
};

export const transformArray = (arr) => {
  return arr.flatMap((item) => {
    const userObject = {
      id: Math.floor(Math.random() * 1e12), // Example conversion to a numeric ID
      message: item.user_prompt,
      type: "user",
    };

    const botObject = {
      delay: undefined,
      id: Math.floor(Math.random() * 1e13), // Incremented ID
      loading: false,
      message: "",
      payload: item.response,
      type: "bot",
      widget: "message",
    };

    return [userObject, botObject];
  });
};
