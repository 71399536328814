import React from "react";

const LoaderWidget = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-start"}}>
      <img
        src="/final daizy icon.png"
        alt=""
        style={{ width: "40px", height: "40px" }}
      />
      <img
        src="/loader.gif"
        width="60px"
        height="60px"
        alt="loader"
        style={{
          float: "left",
          paddingBottom: "80px",
          position: "relative",
          bottom: "10px",
        }}
      />
    </div>
  );
};

export default LoaderWidget;
