import Options from "./Options";
import { getAgent, getFaq } from "../common/helper";

const GeneralOptions = (props) => {
  const options = {
    Document: [
      {
        name: "How does the arrangement of wood veneers affect its strength, flexibility, and resistance to warping?",
        handler: getFaq,
        id: 0,
        props,
      },
      {
        name: "What is difference between MDF and plywood?",
        handler: getFaq,
        id: 1,
        props,
      },
      {
        name: "How can I seamlessly blend modern and rustic aesthetics in a space?",
        handler: getFaq,
        id: 2,
        props,
      },
    ],
  };
  return <Options options={options["Document"]} {...props} />;
};

export default GeneralOptions;
