import React, { useState } from "react";
import ProductCard from "../ProductCard";
import { useSessionContext } from "../../context/SessionContext";

const SuggestionCard = (props) => {
  // const [open, setOpen] = useState(true);
  const { open, setOpen, suggestions } = useSessionContext();
  return (
    <div
      style={{
        position: "absolute",
        bottom: "50px",
        right: "10px",
        zIndex: "1",
        backgroundColor: "white",
        width: "98%",
      }}
    >
      <div
        style={{
          textAlign: "start",
          fontSize: "0.8rem",
          fontWeight: "600",
          color: "black",
          marginLeft: "12px",
          cursor: "pointer",
          width: "fit-content",
          marginTop: "5px",
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
        onClick={() => setOpen(!open)}
      >
        {open && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "auto" }}
          >
            <path
              d="M13.8492 4.18028L13.8535 4.1845L13.8534 4.18455C13.9864 4.32403 14.0605 4.50934 14.0605 4.70205C14.0605 4.89473 13.9864 5.08002 13.8534 5.2195L13.8492 4.18028ZM13.8492 4.18028L13.8452 4.17629L13.8492 4.18028ZM8.54345 10.7925L13.8534 5.21955L7.45746 10.7925C7.5275 10.866 7.61174 10.9245 7.70508 10.9645C7.79842 11.0045 7.89891 11.0251 8.00045 11.0251C8.102 11.0251 8.20248 11.0045 8.29582 10.9645C8.38916 10.9245 8.4734 10.866 8.54345 10.7925C8.54345 10.7925 8.54345 10.7925 8.54345 10.7925ZM2.1475 5.21955L7.45745 10.7925L13.5144 4.24518C13.4571 4.22056 13.3953 4.20787 13.333 4.20787C13.2706 4.20787 13.2088 4.22056 13.1515 4.24518C13.0942 4.26979 13.0424 4.30581 12.9995 4.35105L12.8185 4.1786L12.8182 4.17883C12.8182 4.17884 12.8182 4.17885 12.8182 4.17886L7.99949 9.23658L3.1827 4.17886C3.11637 4.10904 3.03653 4.05344 2.94804 4.01545C2.85955 3.97746 2.76426 3.95787 2.66795 3.95787C2.57165 3.95787 2.47635 3.97746 2.38786 4.01545C2.30046 4.05298 2.2215 4.10767 2.15566 4.17628L2.15167 4.18027L2.15162 4.18022L2.1475 4.18455C2.01453 4.32403 1.94036 4.50934 1.94036 4.70205C1.94036 4.89473 2.01452 5.08002 2.14746 5.2195C2.14747 5.21952 2.14749 5.21953 2.1475 5.21955Z"
              fill="black"
              stroke="black"
            />
          </svg>
        )}
        {!open && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: "auto",rotate: "270deg" }}
          >
            <path
              d="M13.8492 4.18028L13.8535 4.1845L13.8534 4.18455C13.9864 4.32403 14.0605 4.50934 14.0605 4.70205C14.0605 4.89473 13.9864 5.08002 13.8534 5.2195L13.8492 4.18028ZM13.8492 4.18028L13.8452 4.17629L13.8492 4.18028ZM8.54345 10.7925L13.8534 5.21955L7.45746 10.7925C7.5275 10.866 7.61174 10.9245 7.70508 10.9645C7.79842 11.0045 7.89891 11.0251 8.00045 11.0251C8.102 11.0251 8.20248 11.0045 8.29582 10.9645C8.38916 10.9245 8.4734 10.866 8.54345 10.7925C8.54345 10.7925 8.54345 10.7925 8.54345 10.7925ZM2.1475 5.21955L7.45745 10.7925L13.5144 4.24518C13.4571 4.22056 13.3953 4.20787 13.333 4.20787C13.2706 4.20787 13.2088 4.22056 13.1515 4.24518C13.0942 4.26979 13.0424 4.30581 12.9995 4.35105L12.8185 4.1786L12.8182 4.17883C12.8182 4.17884 12.8182 4.17885 12.8182 4.17886L7.99949 9.23658L3.1827 4.17886C3.11637 4.10904 3.03653 4.05344 2.94804 4.01545C2.85955 3.97746 2.76426 3.95787 2.66795 3.95787C2.57165 3.95787 2.47635 3.97746 2.38786 4.01545C2.30046 4.05298 2.2215 4.10767 2.15566 4.17628L2.15167 4.18027L2.15162 4.18022L2.1475 4.18455C2.01453 4.32403 1.94036 4.50934 1.94036 4.70205C1.94036 4.89473 2.01452 5.08002 2.14746 5.2195C2.14747 5.21952 2.14749 5.21953 2.1475 5.21955Z"
              fill="black"
              stroke="black"
            />
          </svg>
        )}

        <span> PRODUCTS</span>
      </div>
      <div
        className={`transition-container ${
          open ? "transition-enter-active" : "transition-exit-active"
        }`}
        style={{ height: "auto" }}
      >
        {open && (
          <div
            style={{
              width: "100%",
              height: suggestions?.length === 0 ? "50px" : "150px",
              overflow: "auto",
              display: "flex",
              flexWrap: "nowrap",
              padding: "10px 0 10px 10px",
              boxSizing: "border-box",
              gap: "10px",
            }}
          >
            {suggestions?.length === 0 && (
              <span
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  fontWeight: "500",
                  fontSize: "0.9rem",
                }}
              >
                No suggestions available Try doing chat first
              </span>
            )}
            {Array.isArray(suggestions) &&
              suggestions.map((suggestion, index) => {
                const imageStringMatch =
                  suggestion.images && suggestion.images.match(/\{([^}]+)\}/);
                const imageString = imageStringMatch ? imageStringMatch[1] : "";
                const imageArray = imageString ? imageString.split(",") : [];
                const firstImage =
                  imageArray.length > 0 ? imageArray[0].trim() : "";
                return (
                  <ProductCard
                    key={index}
                    imageUrl={
                      `${process.env.REACT_APP_DAIZY_PRODUCT_BLOB_URL}/` +
                      encodeURIComponent(firstImage)
                    }
                    packDescription={suggestion.attributes_packagesize}
                    productName={suggestion.category_categoryname}
                    productDescription={suggestion.name}
                    price={
                      suggestion.price_value
                        ? `${suggestion.offerprice_currency}${suggestion.price_value}`
                        : ""
                    }
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestionCard;
