import { createContext, useContext, useState } from "react";

export const SessionContext = createContext();

export const useSessionContext = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [open, setOpen] = useState(false);

  return (
    <SessionContext.Provider
      value={{ suggestions, setSuggestions, open, setOpen }}
    >
      {children}
    </SessionContext.Provider>
  );
};
