import React from "react";

const ProductCard = ({ imageUrl, packDescription, productName, productDescription, price }) => {
  const handleImageError = e => {
    e.target.src = "/stock-product.png"; // Replace with the path to your stock image
  };
  return (
    <div
      style={{
        minWidth: "19rem",
        maxWidth: "19rem",
        display: "flex",
        borderRadius: "10px",
        padding: "8px 8px 8px 0px ",
        boxShadow: "0px 2px 4px 0px #00000040",
      }}
    >
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "80%", border: "", marginTop: "2px" }}>
          <img src={imageUrl} alt={productName} height="80px" width="80px" onError={handleImageError} />
        </div>
        <div style={{ fontSize: "0.8rem", color: "#575757", fontWeight: "500" }}>{packDescription}</div>
      </div>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          paddingRight: "10px",
        }}
      >
        <div
          style={{
            border: "",
            height: "75%",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <div
            style={{
              border: "",
              fontSize: "1rem",
              textAlign: "start",
              color: "#737373",
              fontWeight: "600",
            }}
          >
            {productName}
          </div>
          <div
            style={{
              border: "",
              flex: 1,
              fontSize: "0.9rem",
              textAlign: "start",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "#292929",
              fontWeight: "400",
              wordBreak: "break-all",
            }}
          >
            {productDescription.slice(0, 70)}
          </div>
        </div>
        <div
          style={{
            border: "",
            flex: 1,
            textAlign: "start",
            fontSize: "1rem",
            color: "#000000",
            fontWeight: "700",
          }}
        >
          {price}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
