import React from "react";
import ReactPlayer from 'react-player/youtube'

const BuynowWidget = (props) => {
    let url = "https://youtu.be/Ehcs1ZQExKM?si=f_7LAmhzv8pnsmLx"
  return (
    <div style={{"paddingBottom":"80px"}}>
        <ReactPlayer url={url} width="448px" height="252px"/>
        <a color="black" href="https://dzylo.com/pricing-annually/" target="_blank">Here is the link to start your journey now!</a>
    </div>
  );
};

export default BuynowWidget;