import "./App.css";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./chat-widget/config";
import MessageParser from "./chat-widget/MessageParser";
import ActionProvider from "./chat-widget/ActionProvider";
import { useState, useEffect, useCallback } from "react";
import {
  getSuggestions,
  transformArray,
  updateAgent,
} from "./chat-widget/common/helper";
import { useSearchParams } from "react-router-dom";
import SuggestionCard from "./chat-widget/widgets/SuggestionCard";
import { type } from "@testing-library/user-event/dist/type";
import { useSessionContext } from "./context/SessionContext";
function App() {
  const [viewChat, setViewChat] = useState(false);
  const [isSelected, setSelected] = useState("Document");
  const [theme, setTheme] = useState("dzylo-theme");
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSuggestions, setOpen } = useSessionContext();
  const firstMessage = {
    delay: undefined,
    id: Math.floor(Math.random() * 1e12), // Incremented ID
    loading: false,
    message: "Hi, This is daizy. How can I assist you today?",
    type: "bot",
    widget: "overview",
  };
  const [messages, setMessages] = useState([firstMessage]);

  const source = searchParams.get("source")
    ? searchParams.get("source")
    : "dzylo";

  function openChat() {
    setViewChat(!viewChat);
    if (viewChat) window.top.postMessage("daizy_close", "*");
    else window.top.postMessage("daizy_open", "*");
  }

  useEffect(() => {
    const fetchSessionId = async () => {
      const sessionId = localStorage.getItem("widget_sessionId");
      if (sessionId !== "undefined" && sessionId !== null) {
        return;
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DAIZY_CHAT_BASE_URL}/session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_DAIZY_USERNAME +
                    ":" +
                    process.env.REACT_APP_DAIZY_PASSWORD
                ),
            },
          }
        );
        const data = await response.json();
        localStorage.setItem("widget_sessionId", data);
      } catch (error) {
        console.log("Error in fetching sessionId", error);
      }
    };

    const fetchMessages = async () => {
      let tempArray = [];
      const sessionId = localStorage.getItem("widget_sessionId");
      if (!sessionId) {
        console.log("Session Id not found in messages");
        return;
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DAIZY_CHAT_BASE_URL}/conversations/${sessionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_DAIZY_USERNAME +
                    ":" +
                    process.env.REACT_APP_DAIZY_PASSWORD
                ),
            },
          }
        );
        if (!response.ok || !response.body) {
          console.log("Error fetching messages");
          return;
        }
        if (response.status === 204) {
          console.log("No messages found for this session");
          setMessages([firstMessage]);
          return;
        }
        if (response.status !== 204) {
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            const msgs = transformArray(data);
            tempArray.push(firstMessage);
            tempArray = [...tempArray, ...msgs];
            setMessages(tempArray);
            if (viewChat) {
              const suggestions = await getSuggestions();
              if (suggestions.length > 0) {
                setSuggestions(suggestions);
                setOpen(true);
              }
            }
          }
        }
      } catch (error) {
        console.log("Error fetching messages", error);
      }
    };

    fetchSessionId();
    fetchMessages();
    switch (source) {
      case "virtuate": {
        setTheme("virtuate-theme");
        break;
      }
      default: {
        setTheme("dzylo-theme");
        break;
      }
    }
  }, [viewChat]);

  useEffect(() => {
    const openAfter = Number(searchParams.get("defaultOpen"));
    if (openAfter) setTimeout(openChat, openAfter * 1000);
  }, []);

  function removeDaizy() {
    window.top.postMessage("daizy_remove", "*");
  }

  function changeAgent(agent) {
    setSelected(agent);
    updateAgent(agent);
  }

  function generateImage() {
    setSelected("Generate_Image");
    updateAgent("Generate_Image");
  }

  function openWhatsapp() {
    const text = `Hi, I want to ask `;
    window.open(
      `https://api.whatsapp.com/send/?phone=8527937217&text=${text}&type=phone_number&app_absent=0`
    );
  }

  const validateInput = (input) => {
    if (input.trim() === "") {
      return false;
    }
    return true;
  };

  const loadMessages = () => {
    return messages;
  };

  return (
    <div className={`App ${theme}`}>
      <div className="App-header" style={{ paddingBottom: "0px !important" }}>
        {viewChat && (
          <div>
            <p className="close-daizy-widget" onClick={() => openChat()}>
              X
            </p>
            <p className="support" onClick={() => openWhatsapp()}>
              <img
                src="/whatsapp.png"
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </p>

            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              headerText="Daizy - Dzylo's AI Assistant"
              validator={validateInput}
              placeholderText="Type your message here..."
              messageHistory={loadMessages()}
            />

            {/* <footer className="footer">
              <span>
                Powered by <a href="https://www.virtuate.ai">Virtuate</a>
              </span>
            </footer> */}
          </div>
        )}
        {viewChat && <SuggestionCard />}
        {!viewChat && (
          <div
            style={{
              position: "relative",
              width: "88px",
              height: "88px",
            }}
            className="daizy-chat-image"
          >
            <div
              style={{
                overflow: "hidden",
                borderRadius: "50%",
                marginLeft: "auto",
              }}
            >
              <img
                src="/final daizy icon.gif"
                alt=""
                style={{
                  width: "88%",
                  height: "auto",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => openChat()}
              />
            </div>
            <div
              className="remove-daizy"
              onClick={() => removeDaizy()}
              style={{
                float: "right",
                color: "black",
                top: "0px",
                fontSize: "14px",
                display: "flex",
                right: "4px",
                position: "absolute",
                cursor: "pointer",
                opacity: 0,
                zIndex: 999999999,
              }}
            >
              X
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
