const Options = props => {
  return (
    <div className="options">
      <div
        style={{
          fontSize: "0.9rem",
          display: "flex",
          padding: "5px",
          gap: "10px",
        }}
      >
        <div>
          <img src="/final daizy icon.png" alt="" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
        </div>
        <div style={{ textAlign: "left" }}>
          <p style={{ marginTop: "10px" }}> Hi, This is daizy. How can I assist you today?</p>
        </div>
      </div>
      <div className="options-container">
        {props.options.map(option => {
          return (
            <div
              className="option-item"
              onClick={() => option.handler(option.id, option.props, option.name)}
              key={option.id}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Options;
