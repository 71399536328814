import React from "react";
import ReactPlayer from 'react-player/youtube'
import { useState, useEffect } from 'react';

const Video = (props) =>{
  // let key = "videoUrl"+props.state.message;
  const [value, setValue] = useState("");
  useEffect(()=>{
    let url = props.state.key;
    setValue(url)
}, []);
  // updateTempSrcNumber();
  return (
    <div>
    <ReactPlayer url={value} width="100%" height="252px" style={{"paddingBottom":"80px", maxWidth:"448px"}}/>
    </div>
  );
};

export default Video;
