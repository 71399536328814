import React from "react";
import ReactMarkdown from "react-markdown";

const Message = (props) => {
  return (
    <div
      style={{
        fontSize: "0.9rem",
        display: "flex",
        padding: "5px",
        gap: "10px",
      }}
    >
      <div>
        <img
          src="/final daizy icon.png"
          alt=""
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      </div>
      <div style={{ textAlign: "left" }}>
        {props.payload ? (
          <ReactMarkdown
            components={{
              p: ({ children }) => (
                <p style={{ marginTop: "8px" }}>{children}</p>
              ),
            }}
          >
            {props.payload}
          </ReactMarkdown>
        ) : (
          <img
            src="/loader.gif"
            width="60px"
            height="60px"
            alt="loader"
            style={{
              float: "left",
              paddingBottom: "80px",
              position: "relative",
              bottom: "10px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Message;
