import { createChatBotMessage } from "react-chatbot-kit";
import WidgetView from "./widgets/widget";
import Overview from "./widgets/Overview";
import Video from "./widgets/Video";
import ImageWidget from "./widgets/Image";
import BuynowWidget from "./dzylo-widgets/Buynow";
import MyCustomAvatar from "./widgets/MyCustomAvatar";
import LoaderWidget from "./widgets/Loader";
import SuggestionCard from "./widgets/SuggestionCard";
import Message from "./widgets/Message";

const config = {
  botName: "Daizy",
  initialMessages: [
    createChatBotMessage(`Hi, This is daizy. How can I assist you today?`, {
      widget: "overview",
    }),
  ],
  customComponents: {
    botAvatar: props => <WidgetView {...props} />,
    userAvatar: props => <MyCustomAvatar {...props} />,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#fc5a6a",
    },
    chatButton: {
      backgroundColor: "#f1f1f1",
    },
  },
  customMessages: {
    loader: props => <LoaderWidget {...props} />,
  },
  state: {
    question: "",
  },
  widgets: [
    {
      widgetName: "overview",
      widgetFunc: props => <Overview {...props} />,
    },
    {
      widgetName: "video",
      widgetFunc: props => <Video {...props} />,
    },
    {
      widgetName: "image",
      widgetFunc: props => <ImageWidget {...props} />,
    },
    {
      widgetName: "dzylo-buy-now",
      widgetFunc: props => <BuynowWidget {...props} />,
    },
    {
      widgetName: "suggestions",
      widgetFunc: props => <SuggestionCard {...props} />,
    },
    {
      widgetName: "message",
      widgetFunc: props => <Message {...props} />,
    },
  ],
};

export default config;
